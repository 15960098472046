import { provideAnimations } from '@angular/platform-browser/animations';
import { TuiDialogModule, TuiRootModule } from '@taiga-ui/core';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  EnvironmentInjector,
  EnvironmentProviders,
  LOCALE_ID,
  Provider,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import {
  KeycloakAngularModule,
  KeycloakBearerInterceptor,
  KeycloakService,
} from 'keycloak-angular';

import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { VermoApiModule } from '@vermo-core/api.module';
import { TranslocoHttpLoader } from './transloco-loader';
import {
  TranslocoService,
  getBrowserLang,
  provideTransloco,
} from '@jsverse/transloco';
import { TuiDialogHostModule } from '@taiga-ui/cdk';
import { environment } from 'src/environments/environment';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { confirmKeycloakRealmExist } from '@vermo-core/interceptors/match-failed-keycloak.interceptor';

export const TENANT_ID_LS_KEY = 'tenantId';
export const LANGUAGE_KEY = 'lang';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

async function checkTenantExistence() {
  const path = window.location.pathname.replace('/', '').split('/');
  const potentialRealm = path[0];
  if (!sessionStorage.getItem(TENANT_ID_LS_KEY)) {
    return fetch(`${environment.keycloakUrl}/realms/${potentialRealm}`)
      .then((res) => {
        if (sessionStorage.getItem(TENANT_ID_LS_KEY) !== potentialRealm) {
          sessionStorage.setItem(TENANT_ID_LS_KEY, potentialRealm);
          window.location.reload();
        }
      })
      .catch((err) => {
        if (sessionStorage.getItem(TENANT_ID_LS_KEY)) {
          sessionStorage.removeItem(TENANT_ID_LS_KEY);
          window.location.reload();
        }
      });
  }
}
checkTenantExistence();

function initializeKeycloak(keycloak: KeycloakService) {
  const tenantId = window.sessionStorage.getItem(TENANT_ID_LS_KEY);
  if (!tenantId || tenantId === 'undefined') {
    return () => false;
  }
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: tenantId || 'platform-ui-dev',
        clientId: 'platform-ui',
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
    });
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },

    provideTransloco({
      config: {
        availableLangs: ['en', 'pl'],
        defaultLang:
          window.localStorage.getItem(LANGUAGE_KEY) || getBrowserLang() || 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale({
      defaultLocale: 'pl-PL',
    }),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([confirmKeycloakRealmExist]),
    ),

    provideAnimations(),
    importProvidersFrom(
      TuiRootModule,
      VermoApiModule,
      KeycloakAngularModule,
      TuiDialogModule,
      TuiDialogHostModule,
    ),
    { provide: LOCALE_ID, useValue: 'en-UK' },
    // KeycloakService,
    provideRouter(routes),
  ],
};

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { TENANT_ID_LS_KEY } from 'src/app/app.config';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly keycloak: KeycloakService,
    private readonly router: Router,
    private readonly uiService: UiService,
  ) {}

  hasRoles(roles: string[]) {
    return roles.every((role) =>
      this.keycloak.getUserRoles(true).includes(role),
    );
  }

  async isLogged(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!window.sessionStorage.getItem(TENANT_ID_LS_KEY)) {
      window.location.href = '/';
    }
    if (!this.keycloak.isLoggedIn()) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    return this.keycloak.isLoggedIn();
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    const requiredRoles = route.data['roles'];

    await this.keycloak.loadUserProfile();
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }

    const hasAccess = this.hasRoles(requiredRoles);

    if (!hasAccess) {
      await this.router.navigate([
        window.sessionStorage.getItem(TENANT_ID_LS_KEY),
      ]);
      this.uiService.alertWarn({
        key: 'you-were-redirected-due-to-access-denied',
      });
    }
    return hasAccess;
  }
}

import { Routes, provideRouter, withViewTransitions } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { isLoggedGuard } from '@vermo-core/services/auth-guard.service';

export const routes: Routes = [
  {
    path: 'sign-in',
    providers: [provideRouter([], withViewTransitions({}))],
    loadComponent: () =>
      import('./screens/sign-in-page/sign-in-page.component').then(
        (cmp) => cmp.SignInPageComponent,
      ),
  },
  {
    path: ':tenantId',
    canActivate: [isLoggedGuard],
    loadChildren: () =>
      import('./screens/layout-page/layout-page.routes').then(
        (mod) => mod.LAYOUT_ROUTES,
      ),
  },
  {
    path: '**',
    redirectTo: 'sign-in',
  },
];

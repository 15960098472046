import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { TuiAlertService, TuiNotificationT } from '@taiga-ui/core';

export type TranslocoKey = {
  key: string;
};

@Injectable({ providedIn: 'root' })
export class UiService {
  constructor(
    private readonly alerts: TuiAlertService,
    private readonly transloco: TranslocoService,
  ) {}

  alertError(msg: TranslocoKey) {
    this.alert(this.transloco.translate('error-occurred'), msg.key, 'warning');
  }

  alertWarn(msg: TranslocoKey) {
    this.alert(this.transloco.translate('warning'), msg.key, 'warning');
  }

  alertSuccess(msg: TranslocoKey) {
    this.alert(this.transloco.translate('success'), msg.key, 'success');
  }

  private alert(label: string, msg: string, status: TuiNotificationT) {
    this.alerts
      .open(this.transloco.translate(msg), {
        label: label,
        autoClose: 5_000,
        hasCloseButton: true,
        status,
      })
      .subscribe();
  }
}

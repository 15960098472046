import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptorFn,
} from '@angular/common/http';
import { Observable, catchError, delay, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TENANT_ID_LS_KEY } from 'src/app/app.config';
import { HttpResponse } from '@capacitor/core';

export const confirmKeycloakRealmExist: HttpInterceptorFn = (req, next) => {
  if (req.url.includes(`3p-cookies/step1.html`)) {
    return next(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          sessionStorage.removeItem(TENANT_ID_LS_KEY);
          location.reload();
        }
        return of(err as unknown as HttpEvent<any>);
      }),
    );
  }
  return next(req);
};

@Injectable()
export class MatchFailedKeycloakInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.includes(`/protocol/openid-connect/3p-cookies/step1.html`)) {
      return next.handle(req).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            sessionStorage.removeItem(TENANT_ID_LS_KEY);
            location.reload();
          }
          return of(err as unknown as HttpEvent<any>);
        }),
      );
    }
    return next.handle(req);
  }
}

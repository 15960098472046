import { NgModule } from '@angular/core';
import { BillingApiModule } from '@generated/billing';
import {
  Configuration,
  FactoryDeviceApiModule,
} from '@generated/factory-device';

class VermoConfiguration {
  basePath = '';
  selectHeaderContentType = () => undefined;
  selectHeaderAccept = () => undefined;
  isJsonMime = () => true;

  constructor(config: Partial<VermoConfiguration>) {
    Object.assign(this, config);
  }
}

const configProvider = (config: Partial<VermoConfiguration>) => () =>
  new VermoConfiguration(config);

@NgModule({
  imports: [
    FactoryDeviceApiModule.forRoot(
      configProvider({ basePath: '/api/device-factory' }),
    ),
    BillingApiModule.forRoot(
      configProvider({ basePath: '/api/customer-management' }),
    ),
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class VermoApiModule {}
